import ng from 'angular';
import { AcpCardUseSettingsService } from './acp-card-use-settings-service';
import { RESTRICTION_TYPE } from './acp-restriction-type-constants';
import acpCardUseSettingsTemplate from './templates/acp-card-use-settings.html';
import {
  SubaccountRestrictionsData,
  SubaccountRestrictionsResponse
} from './types';

export class AcpCardUseSettingsComponentCtrl
  implements nsUtils.NsComponentController {
  private _loading: boolean = false;
  private _error: boolean = false;

  // Set default values for restrictions ("Disabled" state)
  private _spendingLimit: number | string = 'none';
  private _hasSpendingLimit: boolean = false;
  private _atmWithdrawalAllowed: string = 'not-allowed';
  private _mccRestrictionsEnabled: string = 'none';

  constructor(
    private nsUtil: nsUtils.NsUtilService,
    private $filter: ng.IFilterService,
    private $log: ng.ILogService,
    private acpCardUseSettingsService: AcpCardUseSettingsService
  ) {
    'ngInject';
  }

  public $onInit(): void {
    this._loading = true;

    this.acpCardUseSettingsService
      .getRestrictions()
      .then(this.onSuccess.bind(this), this.onFailure.bind(this));
  }

  private onSuccess(data: SubaccountRestrictionsResponse): void {
    this._loading = false;

    this.nsUtil.forEach(
      data.restrictions,
      (restriction: SubaccountRestrictionsData) => {
        if (restriction.enabled) {
          switch (restriction.restriction_type) {
            case RESTRICTION_TYPE.DAILY_SPEND_LIMIT_RESTRICTION: {
              this._hasSpendingLimit = true;

              this._spendingLimit = this.$filter('currency')(
                this.nsUtil.toDollars(restriction.restriction_parameters[0]
                  .value as number)
              );
              break;
            }
            case RESTRICTION_TYPE.CASH_ACCESS_RESTRICTION: {
              this._atmWithdrawalAllowed = 'allowed';
              break;
            }
            case RESTRICTION_TYPE.MCC_RESTRICTION: {
              this._mccRestrictionsEnabled = 'not-allowed-mcc';
              break;
            }
            default: {
              this.$log.debug(
                'Unknown restriction: ',
                restriction.restriction_display_name
              );
              break;
            }
          }
        }
      }
    );
  }

  private onFailure(error: any): void {
    this._error = true;
    this.$log.debug('Get Card Use Settings error: ', error);
  }

  get spendingLimit(): number | string {
    return this._spendingLimit;
  }

  set spendingLimit(value: number | string) {
    this._spendingLimit = value;
  }

  get hasSpendingLimit(): boolean {
    return this._hasSpendingLimit;
  }

  set hasSpendingLimit(value: boolean) {
    this._hasSpendingLimit = value;
  }

  get atmWithdrawalAllowed(): string {
    return this._atmWithdrawalAllowed;
  }

  set atmWithdrawalAllowed(value: string) {
    this._atmWithdrawalAllowed = value;
  }

  get mccRestrictionsEnabled(): string {
    return this._mccRestrictionsEnabled;
  }

  set mccRestrictionsEnabled(value: string) {
    this._mccRestrictionsEnabled = value;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get error(): boolean {
    return this._error;
  }

  set error(value: boolean) {
    this._error = value;
  }
}

export const acpCardUseSettings: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpCardUseSettingsComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpCardUseSettingsTemplate
};

export default acpCardUseSettings;
