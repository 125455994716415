import ng from 'angular';
import acpCore from 'core';
import {
  acpCardUseSettings,
  AcpCardUseSettingsComponentCtrl
} from './acp-card-use-settings-component';
import { AcpCardUseSettingsService } from './acp-card-use-settings-service';
import acpCardUseSettingsContent from './card-use-settings-content.yml';
import './styles/${theme}/core.scss';

export * from './types';

export const acpCardUseSettingsModule = ng
  .module('acp.section.card-use-settings', [acpCore.name])
  .component('acpCardUseSettings', acpCardUseSettings)
  .service('acpCardUseSettingsService', AcpCardUseSettingsService)
  .run((contentSectionCache) => {
    'ngInject';

    contentSectionCache.put(
      'sections/card-use-settings',
      acpCardUseSettingsContent
    );
  });

export default acpCardUseSettingsModule;
export { AcpCardUseSettingsComponentCtrl };
